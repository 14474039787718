import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import CurrencyIcon from '@components/CurrencyIcon'
import { useMatchMedia } from '@hooks'
import IBANIcon from '@images/icons/iban-icon.svg?react'
import LeftArrow from '@images/icons/left-arrow-button.svg?react'
import RightArrow from '@images/icons/right-arrow-button.svg?react'

import { currenciesAlpha3, cutString, getCurrencySymbol, isValidArray, tr } from 'mmfintech-commons'
import { AccountBalanceOut } from 'mmfintech-commons-types'

import './styled/CarouselCards.scss'

const CarouselCards = ({
  accounts,
  selectedAccount,
  setSelectedAccount
}: {
  accounts: AccountBalanceOut[]
  selectedAccount: AccountBalanceOut
  setSelectedAccount: Dispatch<SetStateAction<AccountBalanceOut>>
}) => {
  const carouselRef = useRef(null)
  const [selectable, setSelectable] = useState<boolean>(true)
  const prev = () => selectAccountElement(-1)
  const next = () => selectAccountElement(1)
  const isMobile = useMatchMedia({ breakpoint: 450, prefix: 'max' })

  const selectAccountElement = (indexOffset: number, atIndex?: number): void => {
    if (carouselRef && carouselRef.current) {
      const selectedIndex = atIndex != undefined ? atIndex : accounts.indexOf(selectedAccount)
      const cardsNodeList = carouselRef.current.querySelectorAll('.carousel-cards-wrapper')
      if (selectedIndex >= 0 && accounts[selectedIndex + indexOffset]) {
        setSelectedAccount(accounts[selectedIndex + indexOffset])
        if (cardsNodeList && cardsNodeList[selectedIndex + indexOffset]) {
          if (atIndex <= 0 || indexOffset < 0) {
            cardsNodeList[selectedIndex + indexOffset].scrollIntoView({
              behavior: 'smooth',
              inline: 'end'
            })
          } else {
            cardsNodeList[selectedIndex + indexOffset].scrollIntoView({
              behavior: 'smooth',
              inline: 'start'
            })
          }
        }
      }
    }
  }

  const onMouseDown = useCallback(e => {
    e.preventDefault()

    const body = document.body

    const drag = mouseOverEvent => {
      body.style.cursor = 'pointer'
      setSelectable(false)
      carouselRef.current.scroll({
        left: (carouselRef.current.scrollLeft -= mouseOverEvent.movementX)
      })
    }
    carouselRef.current.addEventListener('mousemove', drag)
    const cancelDrag = () => {
      carouselRef.current.removeEventListener('mousemove', drag)
      body.style.cursor = 'default'
      setTimeout(() => {
        setSelectable(true)
      }, 200)
    }
    carouselRef.current.addEventListener('mouseup', cancelDrag, { once: true })
    carouselRef.current.addEventListener('mouseleave', cancelDrag, { once: true })
  }, [])

  useEffect(() => {
    carouselRef.current.addEventListener('mousedown', onMouseDown)
  }, [])
  return (
    <div data-test='carousel-cards-main-test' className='carousel-cards-main'>
      <div className='carousel-cards-arrows-wrapper'>
        <span data-test='account-header-title-test' className='account-header-title'>
          {tr('FRONTEND.ACCOUNTS.MY_ACCOUNTS', 'My accounts')}
        </span>
        {isValidArray(accounts) && accounts.length > 2 && (
          <div className='carousel-arrows'>
            <button
              className='carousel-cards-arrow'
              onClick={e => {
                e.preventDefault()
                prev()
              }}>
              <LeftArrow />
            </button>
            <button
              className='carousel-cards-arrow'
              onClick={e => {
                e.preventDefault()
                next()
              }}>
              <RightArrow />
            </button>
          </div>
        )}
      </div>
      <div data-test={`carousel-cards-container-test`} className='carousel-cards-container' ref={carouselRef}>
        {isValidArray(accounts) &&
          selectedAccount &&
          accounts.map((account, i) => {
            const selected = account.id === selectedAccount.id
            const { currencyCode, balance, id, dedicatedMerchantBankAccounts, name } = account || {}
            const { iban } = dedicatedMerchantBankAccounts[0] || {}
            return (
              <div
                data-test={`carousel-cards-wrapper-${i}-test`}
                key={id}
                className={`${'carousel-cards-wrapper'}${selected ? ' active' : ''} ${
                  accounts.length <= 2 ? 'two-accounts' : ''
                }`}
                onClick={() => {
                  if (selectable) {
                    selectAccountElement(0, i)
                  }
                }}>
                <div style={{ overflow: 'hidden', width: 'calc(100% - 3.2rem)' }}>
                  <div data-test={`carousel-cards-header-${i}-test`} className='carousel-cards-header'>
                    {currencyCode && <CurrencyIcon currency={currencyCode} circle width='4rem' height='4rem' />}
                    <div
                      data-test={`carousel-cards-currency-title-${i}-test`}
                      className='carousel-cards-currency-title'>
                      {isMobile
                        ? `${name ? (name?.length > 18 ? cutString(name, 18) : name) : 'N/A'}`
                        : name || currenciesAlpha3[currencyCode]?.name}
                    </div>
                  </div>
                  <div data-test={`carousel-cards-header-dot-${i}-test`} className='carousel-cards-header-dot'></div>

                  <div data-test={`carousel-cards-content-${i}-test`} className='carousel-cards-content'>
                    <span>
                      {getCurrencySymbol(currencyCode)} {balance}
                    </span>
                  </div>
                </div>
                {iban && (
                  <div data-test={`carousel-cards-content-${i}-test`} className='carousel-cards-content-iban'>
                    <div className='carousel-cards-label-wrapper'>
                      <IBANIcon />
                      <span style={{ maxWidth: 0 /*otherwise breaks responsiveness*/ }}>
                        {tr('FRONTEND.ACCOUNTS.IBAN', 'IBAN')}
                      </span>
                    </div>
                    <span style={{ maxWidth: 0 /*otherwise breaks responsiveness*/ }}>{iban}</span>
                  </div>
                )}
              </div>
            )
          })}
      </div>

      {/* <div className='carousel-cards-dots'>
        {isValidArray(accounts) && accounts.length > 2 && 
          accounts.length > 1 &&
          accounts.map((_, index) => {
            return (
              <div
                className={cn('carousel-cards-dots-element', { active: accounts.indexOf(selectedAccount) == index })}
                key={'card-dot' + index}
                onClick={() => selectAccountElement(0, index)}>
                <span> </span>
              </div>
            )
          })}
      </div> */}
    </div>
  )
}

export default CarouselCards
