import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import CardsCarousel from '@components/CardsCarousel'
import CookieConsent from '@views/cookieConsent/CookieConsent'

import {
  resetEmailVerification,
  selectInitialEmailVerification,
  useAppSelector,
  useGetMerchantQuery
} from 'mmfintech-backend-api'
import { GlobalContext } from 'mmfintech-commons'
import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import './dashboard.scss'
import CardsBanner from './elements/CardsBanner'
import DashboardRecentActivity from './elements/DashboardRecentActivity'
import DownloadAppComponent from './elements/DownloadAppComponent'
import Guideline from './elements/Guideline'
import TransactionsSection from './elements/TransactionsSection'
import WelcomeModal from './elements/WelcomeModal'

function Dashboard() {
  const { modalShow } = useContext(GlobalContext)
  const initialEmailVerification = useAppSelector(selectInitialEmailVerification)
  const { data: merchant } = useGetMerchantQuery()
  const { onboardingStatus, accountType, capabilities } = merchant || {}
  // const featureFlag = true;

  const dispatch = useDispatch()

  useEffect(() => {
    if (initialEmailVerification) {
      modalShow({
        header: 'Welcome',
        options: {
          size: 'medium',
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal />
      })
      dispatch(resetEmailVerification())
    }
    // eslint-disable-next-line
  }, [initialEmailVerification])

  const transactionFilter = {
    page: 0,
    size: 5,
    sort: 'reqTime-desc,id-desc'
  }
  return (
    <div className='dashboard-container'>
      <div className='dashboard-two-selection-container'>
        <div className='dashboard-right-section-container'>
          <div className='dashboard-wrapper'>
            <div data-test='dashboard-right-side' className='right-side'>
              {onboardingStatus == OnboardingStatusEnum.APPROVED &&
              accountType !== MerchantAccountTypeEnum.PROSPECT &&
              capabilities.enableCardIssuing ? (
                <CardsBanner />
              ) : (
                <Guideline />
              )}
              <DashboardRecentActivity />
              <DownloadAppComponent />
              <CookieConsent />
            </div>
          </div>
        </div>
        <div className='dashboard-main-section-container'>
          <CardsCarousel />
          <div className='dashboard-wrapper'>
            <div data-test='dashboard-left-side' className='left-side'>
              <TransactionsSection filter={transactionFilter} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
