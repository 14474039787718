import React from 'react'

import ResendIcon from '@images/icons/resend-icon.svg?react'

import { globalSettings, useOtpQry } from 'mmfintech-backend-api'
import { tr, useValidUntilTimer } from 'mmfintech-commons'
import { LoginStatusEnum, TwoFactorType } from 'mmfintech-commons-types'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import CoreButton from '../CoreButton'
import OtpInput from '../OtpInput/OtpInput'
import './otp.scss'

import EmailVerifyIcon from '@images/icons/e-mail-verification-icon.png'

interface prepareTitle {
  twoFactorType: TwoFactorType
  userStatus: any
}

export const prepareTitle = ({ twoFactorType, userStatus }: prepareTitle) => {
  if (twoFactorType === 'SMS') {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
    }

    return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
  }

  if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
    return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'E-mail Verification')
  }

  return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'E-mail Confirmation')
}

export const Otp = () => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    handleCodeChanged,
    handleSubmit,
    handleResend,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendTimer
  } = useOtpQry()

  const timer = useValidUntilTimer()

  const internalSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    void handleSubmit()
  }

  return (
    <form className='otp-wrapper' noValidate onSubmit={internalSubmit}>
      <div style={{ marginTop: '-1rem' }}>
        <img src={EmailVerifyIcon} alt='email-verify-icon' />
      </div>
      <div data-test='otp-subtitle' className='subtitle'>
        {tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', 'Please enter the verification code that we’ve sent')}{' '}
        <p data-test='otp-send-to'>{challenge?.sentTo}</p>
      </div>

      <ErrorDisplay error={verifyOtpError} />

      {resendOtpFetching || verifyOtpFetching ? (
        <Spinner />
      ) : (
        <div className='content-wrapper'>
          <OtpInput
            codeLength={codeSize}
            onChange={value => handleCodeChanged(value)}
            disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
          />
          <div className='countdown'>{timer.formattedTime}</div>
          <div>
            {attempts < globalSettings.otpMaxAttempts ? (
              <CoreButton
                data-test='otp-re-send-button'
                onClick={handleResend}
                disabled={!resendTimer.expired}
                LeftIcon={resendTimer.expired ? <ResendIcon /> : null}
                type='button'
                title={
                  resendTimer.expired
                    ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send')
                    : (resendTimer.remainingTime as string)
                }
                variation={'secondary'}
                size='normal'
              />
            ) : (
              <div className=''>{tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')}</div>
            )}
          </div>
          <div className='continue-button'>
            <CoreButton
              data-test='otp-confirm-button'
              fullWidth
              title={tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
              disabled={code.length < codeSize || timer.expired || attempts >= globalSettings.otpMaxAttempts}
              size='normal'
              variation={'primary'}
            />
          </div>
        </div>
      )}
    </form>
  )
}
