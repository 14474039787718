import cn from 'classnames'

import './flagIcon.scss'

interface FlagIconProps {
  countryCode?: string
  width?: number | string
  height?: number | string
  circle?: boolean
  className?: string
}

const FlagIcon = ({ countryCode, width = '3rem', height = '2rem', circle = false, className = '' }: FlagIconProps) => {
  return (
    <span
      data-test={`flag-icon-${countryCode}`}
      style={{ width, height }}
      className={cn('flag-icon-wrapper', className, {
        circle,
        [countryCode?.replace(/\./g, '-')]: true
      })}></span>
  )
}

export default FlagIcon
