import { useContext } from 'react'

import CoreButton from '@components/CoreButton'

import { GlobalContext, tr } from 'mmfintech-commons'

import './styled/welcomeModal.scss'

function WelcomeModal() {
  const { modalHide } = useContext(GlobalContext)

  return (
    <div className='welcome-modal-container'>
      <div className='welcome-modal-wrapper' data-test='welcome-modal'>
        <div className='welcome-modal-content'>
          <h2 data-test='welcome-modal-title'>{tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to Jeton!')}</h2>

          <div data-test='welcome-modal-subtitle' className='welcome-text'>
            {tr('FRONTEND.WELCOME.MODAL.ACTIONS.TEXT', 'Your can now deposit, exchange and send!')}
          </div>

          <div data-test='welcome-modal-content' className='welcome-text italic'>
            {tr(
              'FRONTEND.WELCOME.MODAL.COMPLETE.TEXT',
              'Complete your account to get full access!'
            )}
          </div>
          <div className='buttons-wrapper'>
            <CoreButton
              data-test='welcome-modal-button'
              title={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.BUTTON', 'Get Started')}
              variation='primary'
              size='normal'
              fullWidth
              onClick={() => {
                modalHide()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeModal
