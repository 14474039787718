import { useContext } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

import CoreButton from '@components/CoreButton'

import { paths, useTerminateCardMutation } from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, tr, translateError } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

interface RequestNewCardProps {
  prepaidCardId?: string
}

const RequestNewCard = ({ prepaidCardId }: RequestNewCardProps) => {
  const history = useHistory()

  const { setOtpOnSuccess } = useContext(OtpContext)
  const { modalHide } = useContext(GlobalContext)

  const [terminateCard, { isLoading: isTerminating, error: terminationError }] = useTerminateCardMutation()

  const handleTerminateCard = async () => {
    if (prepaidCardId) {
      setOtpOnSuccess(() => result => {
        if (result) {
          toast.success(tr('FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.SUCCESS', 'Card terminated successfully!'))
          return history.push(paths.dashboard())
        }
      })
      try {
        const result = await terminateCard({
          prepaidCardId
        }).unwrap()
        if (!result.challenge) {
          toast.success(tr('FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.SUCCESS', 'Card terminated successfully!'))
          return history.push(paths.dashboard())
        }
      } catch (error) {
        const message = translateError(error)
        if (message) {
          toast.remove()
          toast.error(message)
        }
      }
    }
  }

  return (
    <div className='terminate-card-wrapper'>
      <div className='terminate-card-text'>
        {tr(
          'FRONTEND.CARDS.SETTINGS.REQUEST_CARD.MODAL.TEXT',
          'Old card will be terminated. Do you wish to request a new card?'
        )}
      </div>
      <ErrorDisplay error={terminationError} />
      <div className='terminate-card-actions'>
        <CoreButton
          fullWidth
          type='button'
          variation='primary'
          size='normal'
          isLoading={isTerminating}
          title={tr('FRONTEND.CARDS.SETTINGS.REQUEST_CARD.MODAL.REQUEST_BUTTON', 'Request new card')}
          onClick={() => handleTerminateCard()}
        />
        {!isTerminating && (
          <CoreButton
            type='button'
            variation='secondary'
            size='normal'
            title={tr('FRONTEND.CARDS.SETTINGS.REQUEST_CARD.MODAL.CANCEL_BUTTON', 'Cancel')}
            onClick={() => modalHide()}
          />
        )}
      </div>
    </div>
  )
}

export default RequestNewCard
