import { useContext } from 'react'

import CardOrderModal from '@components/CardOrderModal'
import CoreButton from '@components/CoreButton'

import { GlobalContext, tr } from 'mmfintech-commons'

import './inactiveCard.scss'

const InactiveCard = ({ cardType }: { cardType: 'virtual' | 'physical' }) => {
  const { modalShow, modalHide } = useContext(GlobalContext)

  const handleOrderClick = () => {
    modalShow({
      header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
      content: <CardOrderModal onClose={modalHide} />,
      options: {
        closeOnClickOutside: false
      }
    })
  }
  return (
    <div className='inactive-card-wrapper'>
      <div className='inactive-card-message'>
        {cardType == 'physical'
          ? tr(
              'FRONTEND.CARDS.INACTIVE.MESSAGE.PHYSICAL',
              'A contactless debit card will be delivered to you. Your card balance will be GBP with a new account.'
            )
          : tr(
              'FRONTEND.CARDS.INACTIVE.MESSAGE.VIRTUAL',
              'Get virtual card and manage your online payments. Your card balance will be GBP with a new account.'
            )}
      </div>
      <CoreButton
        fullWidth
        size='normal'
        // disabled={cardType == 'physical'}
        title={
          cardType == 'physical'
            ? tr('FRONTEND.CARDS.INACTIVE.ORDER.PHYSICAL', 'Request physical card')
            : tr('FRONTEND.CARDS.INACTIVE.ORDER.VIRTUAL', 'Create virtual card')
        }
        onClick={handleOrderClick}
      />
    </div>
  )
}

export default InactiveCard
