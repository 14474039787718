import { useEffect, useRef, useState } from 'react'

import './otpInput.scss'

interface OptInputProps {
  codeLength?: number
  onChange?: (inputValue: string) => void
  disabled?: boolean
}

const OtpInput = ({ codeLength = 4, onChange, disabled }: OptInputProps) => {
  const [chars, setChars] = useState<string[]>(Array(codeLength).fill(null))

  const check = /^[0-9]$/
  const refs = useRef([])

  useEffect(() => {
    onChange(chars.filter(c => !!c).join(''))
  }, [chars])

  useEffect(() => {
    refs.current[0] && refs.current[0].focus()
  }, [])

  const focusPrevChar = (target: any) => {
    if (target.previousElementSibling !== null) {
      target.previousElementSibling.focus()
    }
  }

  const focusNextChar = (target: any) => {
    if (target.nextElementSibling !== null) {
      target.nextElementSibling.focus()
    }
  }

  const handleChange = ({ target }) => {
    if (target.value.match(check)) {
      focusNextChar(target)
    } else {
      target.value = ''
    }
    setModuleOutput()
  }

  const handleKeyDown = ({ target, key }) => {
    if (key === 'Backspace') {
      if (target.value === '' && target.previousElementSibling !== null) {
        target.previousElementSibling.value = ''
        focusPrevChar(target)
      } else {
        target.value = ''
      }
      setModuleOutput()
    } else if (key === 'ArrowLeft') {
      focusPrevChar(target)
    } else if (key === 'ArrowRight') {
      focusNextChar(target)
    }
  }

  const handleFocus = ({ target }) => {
    // const el = target
    // In most browsers .select() does not work without the added timeout.
    setTimeout(() => target.select(), 0)
  }

  const handleOnPaste = (event: any) => {
    event.preventDefault()
    const pasted = event.clipboardData.getData('text/plain')
    let pastedArray = pasted.split('').slice(0, codeLength)

    for (var i = 0; i < pastedArray.length; i++) {
      if (pastedArray[i].match(check)) {
        refs.current[i].value = pastedArray[i]
      } else break
    }
    if (i <= codeLength - 1) {
      refs.current[i].focus()
    } else {
      refs.current[codeLength - 1].focus()
    }
    setModuleOutput()
  }

  const setModuleOutput = () => {
    setChars(prevState => {
      return prevState.map((_, number) => {
        return refs.current[number].value
      })
    })
  }

  const prepareInputs = () => {
    const res = []
    for (let index = 0; index < codeLength; index++) {
      res.push(
        <input
          key={index}
          data-test='otp-first-input'
          type='text'
          disabled={disabled}
          className='opt-input'
          maxLength={1}
          name={`input${index}`}
          ref={element => (refs.current[index] = element)}
          // value={getInputValue(index)}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onFocus={handleFocus}
          onPaste={handleOnPaste}
        />
      )
    }
    return res
  }

  return <div className='otp-input-wrapper'>{prepareInputs()}</div>
}

export default OtpInput
